// src/contexts/ThemeContext.jsx

import React, { createContext, useEffect, useState } from 'react';

export const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  // Check local storage for theme preference
  const storedTheme = localStorage.getItem('theme');
  
  // If no preference is stored, check the system preference
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  
  const [theme, setTheme] = useState(storedTheme ? storedTheme : prefersDark ? 'dark' : 'light');

  useEffect(() => {
    const root = window.document.documentElement;

    if (theme === 'dark') {
      root.classList.add('dark');
    } else {
      root.classList.remove('dark');
    }

    // Store user preference in local storage
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(prev => (prev === 'dark' ? 'light' : 'dark'));
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
