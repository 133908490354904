// src/components/Library/ReturnByQRModal.js

import React, { useState } from 'react';
import QrScanner from '../QrScanner';
import { getBookByUuid } from '../../api/libraryApi'; // Ensure correct path
import placeholderCover from '../../assets/placeholder-cover.png'; // Fallback image

/**
 * ReturnByQRModal
 *
 * A modal that allows users to scan a book's QR code to return it.
 * After scanning, it displays the book's details for confirmation.
 *
 * Props:
 * - isOpen: boolean to control modal visibility
 * - onClose: function to close the modal
 * - onConfirmReturn: function to handle the return action
 * - token: authentication token for API calls
 */
const ReturnByQRModal = ({ isOpen, onClose, onConfirmReturn, token }) => {
  const [scanResult, setScanResult] = useState(null); // Raw UUID from QR
  const [book, setBook] = useState(null); // Book details
  const [loading, setLoading] = useState(false); // Loading state for fetching book

  // Reset modal state when closed
  const resetModal = () => {
    setScanResult(null);
    setBook(null);
    setLoading(false);
  };

  // Handle successful QR scan
  const handleScanSuccess = async (uuidString) => {
    if (!uuidString) {
      return;
    }

    setScanResult(uuidString);
    setLoading(true);
    setBook(null);

    try {
      // Fetch book details by UUID
      const response = await getBookByUuid(uuidString, token);
      const fetchedBook = response.data;

      // Validate fetched data
      if (fetchedBook && fetchedBook.title) {
        setBook(fetchedBook);
      } else {
        setBook(null);
        alert('Book details not found for the scanned UUID.');
      }
    } catch (error) {
      console.error('Error fetching book by UUID:', error);
      alert('Failed to fetch book details. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handle confirming the return of the book
  const handleConfirmReturn = () => {
    if (book && scanResult) {
      onConfirmReturn(scanResult, book.title); // Pass both UUID and title for better UX
      onClose();
      resetModal();
    }
  };

  // Handle scanning again
  const handleScanAgain = () => {
    setScanResult(null);
    setBook(null);
  };

  // If modal is not open, render nothing
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div className="bg-white dark:bg-gray-800 w-full max-w-md rounded shadow-lg p-6 relative">
        {/* Modal Header */}
        <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-4">
          Scan Book QR
        </h2>

        {/* QR Scanner or Book Details */}
        {!book ? (
          <>
            {/* QR Scanner */}
            <div className="mb-4">
              <QrScanner
                onScanSuccess={handleScanSuccess}
                facingMode="environment"
              />
            </div>

            {/* Loading Indicator */}
            {loading && (
              <div className="text-center text-gray-700 dark:text-gray-200">
                Fetching book details...
              </div>
            )}
          </>
        ) : (
          /* Display Book Details */
          <div className="flex flex-col items-center">
            {/* Book Cover */}
            <img
            src={`${'http://noproblemschool.pl'}${book.cover_thumbnail || placeholderCover}`}
            alt={book.title}
            className="w-32 h-40 object-cover rounded-md mb-4 border-2 border-gray-300 dark:border-gray-700"
          />

            {/* Book Title */}
            <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-2">
              {book.title}
            </h3>

            {/* Book Author */}
            <p className="text-sm text-gray-600 dark:text-gray-300 mb-4">
              Author: {book.author}
            </p>

            {/* Action Buttons */}
            <div className="flex space-x-4">
              <button
                onClick={handleConfirmReturn}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition"
              >
                Return Book
              </button>
              <button
                onClick={handleScanAgain}
                className="px-4 py-2 bg-gray-300 dark:bg-gray-600 text-gray-800 dark:text-gray-200 rounded hover:bg-gray-400 dark:hover:bg-gray-500 transition"
              >
                Scan Again
              </button>
            </div>
          </div>
        )}

        {/* Close Button */}
        <button
          onClick={() => {
            onClose();
            resetModal();
          }}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100"
        >
          ✕
        </button>
      </div>
    </div>
  );
};

export default ReturnByQRModal;
