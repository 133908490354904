// src/components/Library/LibraryPage.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import BookList from './BookList';
import BookDetail from './BookDetail';
import AssignBook from './AssignBook';
import BorrowBook from './BorrowBook';
import ReturnBook from './ReturnBook';
import PrivateRoute from '../../auth/PrivateRoute';
import Unauthorized from '../Unauthorized';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext'; // Only if you decide to implement it

const LibraryPage = () => {
  const { user, token } = useContext(UserContext); // Accessing user and token from context

  return (
    <div className="library-container">
      <Routes>
        <Route path="/" element={<BookList token={token} />} />
        <Route path=":bookId" element={<BookDetail token={token} user={user}/>} />

        {/* Admin Routes */}
        <Route
          path=":bookId/assign"
          element={
            <PrivateRoute requiredRoles={['admin']}>
              <AssignBook token={token} />
            </PrivateRoute>
          }
        />

        {/* Student Routes */}
        <Route
          path=":bookId/borrow"
          element={
            <PrivateRoute requiredRoles={['student']}>
              <BorrowBook token={token} />
            </PrivateRoute>
          }
        />
        <Route
          path=":bookId/return"
          element={
            <PrivateRoute requiredRoles={['student']}>
              <ReturnBook token={token} />
            </PrivateRoute>
          }
        />

        {/* Unauthorized Access */}
        <Route path="unauthorized" element={<Unauthorized />} />
      </Routes>
    </div>
  );
};

export default LibraryPage;
