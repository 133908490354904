// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainScreen from './components/MainScreen';
import Navbar from './components/Navbar';
import LoginPage from './components/LoginPage';
import PrivateRoute from './auth/PrivateRoute';
import { api } from './auth/auth';
import Spinner from './components/Spinner';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ThemeProvider from './context/ThemeContext';
import LibraryPage from './components/Library/LibraryPage';
import UserProvider from './context/UserContext';
import Leaderboard from './components/Leaderboard';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState(null);

  const handleLoginSuccess = (accessToken) => {
    localStorage.setItem('token', accessToken);
    setToken(accessToken);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setToken(null);
    setUser(null);
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (token) {
        try {
          const response = await api.get('/users/profile');
          console.log('Fetched user profile:', response.data);
          setUser(response.data); // Store the user data including the role
        } catch (error) {
          console.error('Error fetching user profile:', error);
          handleLogout();
        }
      } else {
        setUser(null);
      }
    };
    fetchUserProfile();
  }, [token]);

  if (token && !user) {
    // Show a loading indicator while fetching user data
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <UserProvider>
    <ThemeProvider>
    <Router>
      <Navbar token={token} onLogout={handleLogout} user={user} />
      <div className="bg-gray-200 dark:bg-slate-800 min-h-screen flex flex-col">
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<LoginPage onLoginSuccess={handleLoginSuccess} />} />
          <Route path="/" element={<LoginPage onLoginSuccess={handleLoginSuccess} />} />

          

      <Route
        path="/library/*"
        element={
          <PrivateRoute isAuthenticated={!!token} user={user}>
            <LibraryPage user={user} token={token} />
          </PrivateRoute>
        }
      />
      <Route
        path="/leaderboard"
        element={
          <PrivateRoute isAuthenticated={!!token} user={user}>
            <Leaderboard token={token} />
          </PrivateRoute>
        }
      />
        </Routes>
        <ToastContainer 
        position="top-right" 
        autoClose={3000} 
        hideProgressBar={false} 
        newestOnTop={false} 
        closeOnClick 
        rtl={false} 
        pauseOnFocusLoss 
        draggable 
        pauseOnHover 
      />        
      </div>
      <Footer />
    </Router>
    </ThemeProvider>
    </UserProvider>
  );
}

export default App;
