// src/components/Library/BorrowBook.js

import React from 'react';
import { borrowBook } from '../../api/libraryApi';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const BorrowBook = ({ token }) => {
  const { bookId } = useParams();
  const navigate = useNavigate();

  const handleBorrow = async () => {
    try {
      await borrowBook(bookId, token);
      toast.success('Book borrowed successfully.');
      navigate(`/library/${bookId}`);
    } catch (err) {
      toast.error('Failed to borrow book.');
      console.error(err);
    }
  };

  return (
    <div className="borrow-book">
      <h2>Borrow Book</h2>
      <p>Are you sure you want to borrow this book?</p>
      <button onClick={handleBorrow}>Confirm Borrow</button>
    </div>
  );
};

export default BorrowBook;
