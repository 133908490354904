// src/components/Library/ReturnBook.js

import React from 'react';
import { returnBook } from '../../api/libraryApi';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ReturnBook = ({ token }) => {
  const { bookId } = useParams();
  const navigate = useNavigate();

  const handleReturn = async () => {
    try {
      await returnBook(bookId, token);
      toast.success('Book returned successfully.');
      navigate(`/library/${bookId}`);
    } catch (err) {
      toast.error('Failed to return book.');
      console.error(err);
    }
  };

  return (
    <div className="return-book">
      <h2>Return Book</h2>
      <p>Are you sure you want to return this book?</p>
      <button onClick={handleReturn}>Confirm Return</button>
    </div>
  );
};

export default ReturnBook;
