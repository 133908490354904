// src/components/Library/BookDetail.js

import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  getBookDetails,
  assignBook,
  returnBook,
} from '../../api/libraryApi';
import { toast } from 'react-toastify';
import Spinner from '../Spinner';
import { MdPersonAddAlt1 } from 'react-icons/md';
import { GiReturnArrow } from 'react-icons/gi';
import { FaChevronDown, FaChevronUp, FaCheckCircle, FaTimesCircle, FaUser, FaPhone, FaCalendarAlt } from 'react-icons/fa';
import ConfirmModal from '../common/ConfirmModal';
import UserSelectorModal from '../common/UserSelectorModal';
import { LanguageContext } from '../../context/LanguageContext';

const BookDetail = ({ token, user }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { language } = useContext(LanguageContext);

  const { bookId } = useParams();
  const queryClient = useQueryClient();

  const [username, setUsername] = useState('');
  const [showUserSelector, setShowUserSelector] = useState(false);
  const [showAssignConfirm, setShowAssignConfirm] = useState(false);

  const [showReturnConfirm, setShowReturnConfirm] = useState(false);

  const [showHistory, setShowHistory] = useState(false);
  const [showBorrowerDetails, setShowBorrowerDetails] = useState(false);
  const content = {
    pl: {
      authorLabel: "Autor",
      dateAddedLabel: "Data Dodania",
      shortDescriptionLabel: "Krótki Opis",
      borrowedStatus: "Wypożyczona",
      availableStatus: "Dostępna",
      whoHasItLabel: "Kto ma książkę?",
      studentLabel: "Student",
      contactLabel: "Kontakt",
      borrowedAtLabel: "Wypożyczono",
      returnedAtLabel: "Zwrócono",
      logLabel: "Log",
      noHistoryLabel: "Brak historii.",
      assignLabel: "Przypisz",
      studentsLabel: "Studenci",
      returnLabel: "Zwróć",
      returnBookLabel: "Zwróć Książkę",
      returningText: "Zwracanie...",
      statusText: "Status",
      returnedText: "Zwrócono",
      notReturnedText: "Nie zwrócono",
    },
    en: {
      authorLabel: "Author",
      dateAddedLabel: "Date Added",
      shortDescriptionLabel: "Short Description",
      borrowedStatus: "Borrowed",
      availableStatus: "Available",
      whoHasItLabel: "Who has it?",
      studentLabel: "Student",
      contactLabel: "Contact",
      borrowedAtLabel: "Borrowed At",
      returnedAtLabel: "Returned At",
      logLabel: "Log",
      noHistoryLabel: "No history available.",
      assignLabel: "Assign",
      studentsLabel: "Students",
      returnLabel: "Return",
      returnBookLabel: "Return Book",
      returningText: "Returning...",
      statusText: "Status",
      returnedText: "Returned",
      notReturnedText: "Not Returned",
    },
  };

  const {
    authorLabel,
    dateAddedLabel,
    shortDescriptionLabel,
    borrowedStatus,
    availableStatus,
    whoHasItLabel,
    studentLabel,
    contactLabel,
    borrowedAtLabel,
    returnedAtLabel,
    logLabel,
    noHistoryLabel,
    assignLabel,
    studentsLabel,
    returnLabel,
    returnBookLabel,
    returningText,
    statusText,
    returnedText,
    notReturnedText,
  } = content[language];

  const { data: book, error, isLoading } = useQuery({
    queryKey: ['book', bookId],
    queryFn: () => getBookDetails(bookId, token).then(res => res.data),
    staleTime: 3600000,
    cacheTime: 7200000,
    refetchOnWindowFocus: false,
  });

  const assignMutation = useMutation({
    mutationFn: ({ bookId, username }) => assignBook(bookId, username, token),
    onSuccess: () => {
      toast.success(language === 'pl' ? `Przypisano do "${username}".` : `Assigned to "${username}".`);
      setUsername('');
      setShowAssignConfirm(false);
      queryClient.invalidateQueries(['book', bookId]);
    },
    onError: (error) => {
      console.error(error);
      toast.error(error.response?.data?.detail || (language === 'pl' ? 'Nie udało się przypisać książki.' : 'Failed to assign book.'));
    },
  });

  const returnMutation = useMutation({
    mutationFn: (bookId) => returnBook(bookId, token),
    onSuccess: () => {
      toast.success(language === 'pl' ? 'Książka została zwrócona pomyślnie.' : 'Book returned successfully.');
      setShowReturnConfirm(false);
      queryClient.invalidateQueries(['book', bookId]);
    },
    onError: (error) => {
      console.error(error);
      toast.error(error.response?.data?.detail || (language === 'pl' ? 'Nie udało się zwrócić książki.' : 'Failed to return book.'));
    },
  });

  const handleAssign = () => {
    if (bookId && username) {
      assignMutation.mutate({ bookId, username });
    }
  };

  const handleReturn = () => {
    if (bookId) {
      returnMutation.mutate(bookId);
    }
  };

  const handleUserSelected = (selectedUsername) => {
    setUsername(selectedUsername);
    setShowUserSelector(false);
    setShowAssignConfirm(true);
  };

  if (isLoading) return <Spinner />;

  if (error)
    return <div className="text-center text-red-500 mt-10">{language === 'pl' ? 'Nie udało się załadować szczegółów książki.' : 'Failed to load book details.'}</div>;

  if (!book) return <div className="text-center mt-10">{language === 'pl' ? 'Nie znaleziono książki.' : 'No book found.'}</div>;

  const {
    title,
    author,
    publication_date,
    cover_image,
    is_borrowed,
    current_borrower,
    borrow_history = [],
    short_description,
  } = book;

 

  return (
    <div className="max-w-4xl mx-auto p-6 border-2 border-sky-800 dark:border-white bg-sky-100 dark:bg-gray-800 shadow-lg rounded-lg my-10 text-gray-800 dark:text-gray-200">
      <div className="flex flex-col md:flex-row items-center transition-all duration-300">
        {cover_image && (
          <div className="mb-4 md:mb-0 md:mr-6 flex-shrink-0">
            <img
              src={cover_image}
              alt={`${title} Cover`}
              className="w-48 h-64 object-cover rounded-md shadow-md transform transition-transform duration-300 hover:scale-110"
            />
          </div>
        )}

        <div className="flex-1 text-center md:text-left">
          <h2 className="text-3xl font-bold tracking-tight">{title}</h2>
          <p className="mt-2 text-lg">
            <strong>{authorLabel}:</strong> {author}
          </p>
          {publication_date && (
            <p className="mt-1 text-lg">
              <strong>{dateAddedLabel}:</strong>{' '}
              {new Date(publication_date).toLocaleDateString()}
            </p>
          )}

          {short_description && (
            <p className="mt-3 text-md text-gray-700 dark:text-gray-300 leading-relaxed">
              {shortDescriptionLabel}: {short_description}
            </p>
          )}

          <p
            className={`mt-4 text-xl font-semibold ${
              is_borrowed ? 'text-red-600' : 'text-green-600'
            }`}
          >
            {is_borrowed ? borrowedStatus : availableStatus}
          </p>
        </div>
      </div>

      
      {/* Current Borrower */}
      {is_borrowed && current_borrower && (
        <div className="mt-6">
          <button
            type="button"
            onClick={() => setShowBorrowerDetails(!showBorrowerDetails)}
            className="flex items-center justify-between w-full px-6 py-4 bg-sky-300 dark:bg-sky-900 rounded-md shadow-inner hover:bg-sky-200 dark:hover:bg-sky-800 transition duration-300"
          >
            <span className="text-xl font-semibold text-gray-800 dark:text-gray-200 flex items-center">
              {whoHasItLabel} <FaUser className="ml-2  " />
            </span>
            {showBorrowerDetails ? (
              <FaChevronUp className="  text-xl" />
            ) : (
              <FaChevronDown className="text-yellow-600 dark:text-yellow-300 text-xl" />
            )}
          </button>
          {showBorrowerDetails && (
  <div className="mt-4 p-6 bg-sky-300 dark:bg-sky-900 rounded-md shadow-inner">
    <p className="mt-2 text-lg flex items-center">
      <FaUser className="mr-2 text-gray-700 dark:text-gray-300" />
      <span className='mr-2'><strong>{studentLabel}:</strong> </span>{current_borrower.borrower_firstname} {current_borrower.borrower_lastname}
    </p>
    <p className="mt-1 text-base flex items-center">
      <FaPhone className="mr-2 text-gray-700 dark:text-gray-300" />
      <span className='mr-2'><strong>{contactLabel}:</strong>  </span>{current_borrower.borrower_contact}
    </p>
    <p className="mt-1 text-sm flex items-center">
      <FaCalendarAlt className="mr-2 text-gray-700 dark:text-gray-300" />
      <span className='mr-2'><strong>{borrowedAtLabel}:</strong> </span>{' '}
      {new Date(current_borrower.borrowed_at).toLocaleDateString(language === 'pl' ? 'pl-PL' : 'en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      })}{' '}
      {new Date(current_borrower.borrowed_at).toLocaleTimeString(language === 'pl' ? 'pl-PL' : 'en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })}
    </p>
  </div>
)}
        </div>
      )}

      {user && user.role === 'admin' && (
        <div className="mt-8 space-y-6">
          <div className="p-6 bg-blue-50 dark:bg-gray-700 rounded-md shadow-inner flex justify-between">

              <h3 className="text-xl font-semibold mb-4 flex items-center space-x-2">
                <MdPersonAddAlt1 className="text-xl text-blue-600 dark:text-blue-400" />
                <span>{assignLabel}</span>
              </h3>

              <button
                  type="button"
                  onClick={() => setShowUserSelector(true)}
                  className="sm:w-auto flex items-center justify-center px-6 py-3 text-lg bg-sky-600 text-white rounded-lg hover:bg-sky-700 transition duration-300"
                >
                  {studentsLabel}
                </button>                

          </div>

          <div className="p-6 bg-blue-50 dark:bg-gray-700 rounded-md shadow-inner flex justify-between">

              <h3 className="text-xl font-semibold mb-4 flex items-center space-x-2">
              <GiReturnArrow className="text-xl text-red-600 dark:text-red-400" />
              <span>{returnLabel}</span>
              </h3>
              <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 space-y-4 sm:space-y-0">
                <div className="flex-1">
                </div>
                <button
                type="button"
                onClick={() => setShowReturnConfirm(true)}
                disabled={returnMutation.isLoading || !is_borrowed}
                className={`sm:w-auto flex items-center justify-center px-6 py-3 text-normal bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-300 ${
                  (returnMutation.isLoading || !is_borrowed) && 'opacity-50 cursor-not-allowed'
                }`}
              >
                {returnMutation.isLoading ? returningText : returnBookLabel}
              </button>
              </div>

          </div>

        </div>
      )}

      <div className="mt-10">
        <button
          type="button"
          onClick={() => setShowHistory(!showHistory)}
          className="flex items-center justify-between w-full px-6 py-4 bg-gray-200 dark:bg-gray-700 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition duration-300"
        >
          <span className="text-xl font-semibold text-gray-700 dark:text-gray-200">
            {logLabel}
          </span>
          {showHistory ? (
            <FaChevronUp className="text-gray-600 dark:text-gray-300 text-xl" />
          ) : (
            <FaChevronDown className="text-gray-600 dark:text-gray-300 text-xl" />
          )}
        </button>
        <div
          className={`mt-4 transition-max-height duration-500 ease-in-out overflow-hidden ${
            showHistory ? 'max-h-screen' : 'max-h-0'
          }`}
        >
          <div className="p-6 bg-gray-50 dark:bg-gray-700 rounded-lg shadow-inner">
            {borrow_history.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white dark:bg-gray-600 rounded-lg">
                  <thead>
                    <tr>
                      <th className="py-3 px-6 bg-gray-200 dark:bg-gray-800 text-left text-xs font-medium text-gray-700 dark:text-gray-200 uppercase tracking-wider">
                        {statusText}
                      </th>
                      <th className="py-3 px-6 bg-gray-200 dark:bg-gray-800 text-left text-xs font-medium text-gray-700 dark:text-gray-200 uppercase tracking-wider">
                        {studentLabel}
                      </th>

                      <th className="py-3 px-6 bg-gray-200 dark:bg-gray-800 text-left text-xs font-medium text-gray-700 dark:text-gray-200 uppercase tracking-wider">
                        {borrowedAtLabel}
                      </th>
                      <th className="py-3 px-6 bg-gray-200 dark:bg-gray-800 text-center text-xs font-medium text-gray-700 dark:text-gray-200 uppercase tracking-wider">
                        {returnedAtLabel}
                      </th>
                      <th className="py-3 px-6 bg-gray-200 dark:bg-gray-800 text-left text-xs font-medium text-gray-700 dark:text-gray-200 uppercase tracking-wider">
                        {contactLabel}
                      </th>                      
                    </tr>
                  </thead>
                  <tbody>
                  {borrow_history.map((record) => (
  <tr key={record.id} className="border-b border-gray-200 dark:border-gray-500">
    <td className="py-4 px-2 text-center">
      {record.returned_at ? (
        <FaCheckCircle className="text-green-500 mx-auto" title={returnedText} />
      ) : (
        <FaTimesCircle className="text-red-500 mx-auto" title={notReturnedText} />
      )}
    </td>                        
    <td className="py-4 px-2 text-sm text-gray-700 dark:text-gray-200">
      {record.borrower_firstname} {record.borrower_lastname}
    </td>

    <td className="py-2 px-2 text-xs text-gray-700 dark:text-gray-200">
      {new Date(record.borrowed_at).toLocaleDateString(language === 'pl' ? 'pl-PL' : 'en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      })}{' '} 
      {new Date(record.borrowed_at).toLocaleTimeString(language === 'pl' ? 'pl-PL' : 'en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })}
    </td>
    <td className="py-2 px-2 text-xs text-gray-700 dark:text-gray-200">
      {record.returned_at
        ? `${new Date(record.returned_at).toLocaleDateString(language === 'pl' ? 'pl-PL' : 'en-GB', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
          })} ${new Date(record.returned_at).toLocaleTimeString(language === 'pl' ? 'pl-PL' : 'en-GB', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          })}`
        : '—'}
    </td>
    <td className="py-4 px-2 text-sm text-gray-700 dark:text-gray-200">
      {record.borrower_contact}
    </td>    
  </tr>
))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="text-gray-700 dark:text-gray-300">
                {noHistoryLabel}
              </p>
            )}
          </div>
        </div>
      </div>

      <ConfirmModal
        title={language === 'pl' ? 'Potwierdź' : 'Confirm'}
        message={`${language === 'pl' ? 'Przypisać tę książkę do' : 'Assign this book to'} "${username}"?`}
        isOpen={showAssignConfirm}
        onClose={() => setShowAssignConfirm(false)}
        onConfirm={handleAssign}
        confirmText={language === 'pl' ? 'Tak, Przypisz' : 'Yes'}
        cancelText={language === 'pl' ? 'Anuluj' : 'Cancel'}
      />

      <ConfirmModal
        title={language === 'pl' ? 'Potwierdź' : 'Confirm'}
        message={language === 'pl' ? 'Czy na pewno chcesz zwrócić tę książkę?' : 'Are you sure you want to return this book?'}
        isOpen={showReturnConfirm}
        onClose={() => setShowReturnConfirm(false)}
        onConfirm={handleReturn}
        confirmText={language === 'pl' ? 'Tak, Zwróć' : 'Yes'}
        cancelText={language === 'pl' ? 'Anuluj' : 'Cancel'}
      />

      <UserSelectorModal
        token={token}
        isOpen={showUserSelector}
        onClose={() => setShowUserSelector(false)}
        onSelect={handleUserSelected}
      />
    </div>
  );
};

export default BookDetail;
