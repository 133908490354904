// src/components/Library/BookList.js

import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getBooks, assignBook, returnBook, returnBookByUuid } from '../../api/libraryApi';
import Spinner from '../Spinner';
import placeholderCover from '../../assets/placeholder-cover.png';
import UserSelectorModal from '../common/UserSelectorModal';
import ConfirmModal from '../common/ConfirmModal';
import ReturnByQRModal from '../common/ReturnByQRModal';
import LazyLoad from 'react-lazyload';
import { FaHandHoldingMedical, FaLanguage } from 'react-icons/fa';
import { GiReturnArrow } from 'react-icons/gi';
import { LanguageContext } from '../../context/LanguageContext'; // Import LanguageContext

const BookList = ({ token }) => {
  const [allBooks, setAllBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // For Borrow/Assign
  const [showUserSelector, setShowUserSelector] = useState(false);
  const [showBorrowConfirm, setShowBorrowConfirm] = useState(false);
  const [selectedBookId, setSelectedBookId] = useState(null);
  const [selectedUsername, setSelectedUsername] = useState('');

  // Search & Filter
  const [searchQuery, setSearchQuery] = useState('');
  const [filterStatus, setFilterStatus] = useState('all'); 
  // 'all' | 'borrowed' | 'available'

  // For Return by QR
  const [showQrModal, setShowQrModal] = useState(false);

  // Consume Language Context
  const { language } = useContext(LanguageContext);

  // Content for both languages
  const content = {
    pl: {
      pageTitle: "Lista Książek",
      searchPlaceholder: "Szukaj po tytule lub autorze...",
      filterLabel: "Filtr:",
      filterAll: "Wszystkie",
      filterBorrowed: "Wypożyczone",
      filterAvailable: "Dostępne",
      returnByQR: "Zwróć przez QR",
      noBooks: "Brak książek spełniających kryteria.",
      borrower: "Wypożyczający",
      contact: "Kontakt",
      borrowedAt: "Wypożyczono",
      returnedAt: "Zwrócono",
      status: "Status",
      returned: "Zwrócono",
      notReturned: "Nie zwrócono",
      borrow: "Wypożycz",
      borrowButton: "Wypożycz",
      returnButton: "Zwróć",
      confirmBorrowTitle: "Potwierdź Wypożyczenie",
      confirmBorrowMessage: "Czy na pewno chcesz wypożyczyć tę książkę dla",
      confirmBorrowYes: "Tak, Wypożycz",
      confirmBorrowCancel: "Anuluj",
      confirmReturnTitle: "Potwierdź Zwrot",
      confirmReturnMessage: "Czy na pewno chcesz zwrócić tę książkę?",
      confirmReturnYes: "Tak, Zwróć",
      confirmReturnCancel: "Anuluj",
    },
    en: {
      pageTitle: "Book List",
      searchPlaceholder: "Search by title or author...",
      filterLabel: "Filter:",
      filterAll: "All",
      filterBorrowed: "Borrowed",
      filterAvailable: "Available",
      returnByQR: "Return by QR",
      noBooks: "No books match your search/filter.",
      borrower: "Borrower",
      contact: "Contact",
      borrowedAt: "Borrowed At",
      returnedAt: "Returned At",
      status: "Status",
      returned: "Returned",
      notReturned: "Not Returned",
      borrow: "Borrow",
      borrowButton: "Borrow",
      returnButton: "Return",
      confirmBorrowTitle: "Confirm Borrow",
      confirmBorrowMessage: "Are you sure you want to borrow this book for",
      confirmBorrowYes: "Yes, Borrow",
      confirmBorrowCancel: "Cancel",
      confirmReturnTitle: "Confirm Return",
      confirmReturnMessage: "Are you sure you want to return this book?",
      confirmReturnYes: "Yes, Return",
      confirmReturnCancel: "Cancel",
    },
  };

  const {
    pageTitle,
    searchPlaceholder,
    filterLabel,
    filterAll,
    filterBorrowed,
    filterAvailable,
    returnByQR,
    noBooks,
    borrower,
    contact,
    borrowedAt,
    returnedAt,
    status,
    returned,
    notReturned,
    borrow,
    borrowButton,
    returnButton,
    confirmBorrowTitle,
    confirmBorrowMessage,
    confirmBorrowYes,
    confirmBorrowCancel,
    confirmReturnTitle,
    confirmReturnMessage,
    confirmReturnYes,
    confirmReturnCancel,
  } = content[language];

  useEffect(() => {
    fetchBooks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, language]); // Add 'language' to dependencies if error messages need localization

  const fetchBooks = async () => {
    try {
      setLoading(true);
      const response = await getBooks(token);
      setAllBooks(response.data);
    } catch (err) {
      setError(language === 'pl' ? 'Nie udało się pobrać książek.' : 'Failed to fetch books.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // -----------------------------
  // BORROW / ASSIGN
  // -----------------------------
  const handleBorrowClick = (bookId) => {
    setSelectedBookId(bookId);
    setShowUserSelector(true);
  };

  const handleUserSelected = (username) => {
    setSelectedUsername(username);
    setShowUserSelector(false);
    setShowBorrowConfirm(true);
  };

  const confirmBorrow = async () => {
    setShowBorrowConfirm(false);

    if (!selectedBookId || !selectedUsername) return;

    try {
      await assignBook(selectedBookId, selectedUsername, token);
      toast.success(language === 'pl' ? `Książka wypożyczona przez ${selectedUsername}.` : `Book borrowed by ${selectedUsername}.`);
      fetchBooks(); // Refresh list
    } catch (err) {
      console.error(err);
      toast.error(
        err.response?.data?.detail || (language === 'pl' ? 'Nie udało się wypożyczyć książki.' : 'Failed to borrow book.')
      );
    } finally {
      setSelectedBookId(null);
      setSelectedUsername('');
    }
  };

  // -----------------------------
  // RETURN
  // -----------------------------
  const handleReturnClick = async (bookId) => {
    // Optionally use a custom confirm modal instead of window.confirm
    if (!window.confirm(language === 'pl' ? 'Czy na pewno chcesz zwrócić tę książkę?' : 'Are you sure you want to return this book?')) {
      return;
    }

    try {
      const response = await returnBook(bookId, token);
      if (response.data.success) {
        toast.success(language === 'pl' ? 'Książka została zwrócona pomyślnie.' : 'Book returned successfully.');
      }
      fetchBooks();
    } catch (err) {
      console.error(err);
      toast.error(
        err.response?.data?.detail || (language === 'pl' ? 'Nie udało się zwrócić książki.' : 'Failed to return book.')
      );
    }
  };

  // -----------------------------
  // RETURN BY QR
  // -----------------------------
  const handleReturnByUuid = async (bookUuid, bookTitle) => {
    const confirmMessage = language === 'pl' ? `Zwróć "${bookTitle}"?` : `Return "${bookTitle}"?`;
    const confirmed = window.confirm(confirmMessage);
    if (!confirmed) return;

    try {
      const response = await returnBookByUuid(bookUuid, token);
      if (response.data.success) {
        toast.success(response.data.message);
      }
      fetchBooks(); // Refresh list
    } catch (err) {
      console.error(err);
      toast.error(
        err.response?.data?.detail || (language === 'pl' ? 'Nie udało się zwrócić książki przez UUID.' : 'Failed to return book via UUID.')
      );
    }
    setShowQrModal(false); // Close the QR modal
  };

  // -----------------------------
  // FILTER / SEARCH LOGIC
  // -----------------------------
  const displayedBooks = allBooks.filter((book) => {
    // 1) Filter by search
    // We check both title & author for the query
    const lowerTitle = book.title.toLowerCase();
    const lowerAuthor = book.author.toLowerCase();
    const query = searchQuery.toLowerCase();

    const matchesSearch =
      lowerTitle.includes(query) || lowerAuthor.includes(query);

    if (!matchesSearch) return false;

    // 2) Filter by status
    if (filterStatus === 'borrowed' && !book.is_borrowed) {
      return false;
    }
    if (filterStatus === 'available' && book.is_borrowed) {
      return false;
    }

    return true;
  });

  // -----------------------------
  // RENDER
  // -----------------------------
  if (loading) return <Spinner />;
  if (error) {
    return <div className="text-center text-red-600 mt-4">{error}</div>;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-6 mt-10">
      {/* Page Title */}
      <h2 className="text-3xl font-bold text-gray-800 dark:text-white mb-6">
        {pageTitle}
      </h2>

      {/* Search & Filter Controls */}
      <div className="flex flex-col sm:flex-row sm:items-center mb-6 gap-4">
        {/* Search Box */}
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder={searchPlaceholder}
          className="w-full sm:w-1/2 px-3 py-2 border border-gray-300 dark:border-gray-700 rounded focus:outline-none bg-blue-200 dark:bg-blue-800 dark:text-white"
        />

        {/* Status Filter */}
        <div className="flex space-x-2 items-center">
          <label className="text-gray-800 dark:text-gray-200">{filterLabel}</label>
          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            className="px-3 py-2 border border-gray-300 dark:border-gray-700 rounded focus:outline-none dark:bg-gray-700 dark:text-white"
          >
            <option value="all">{filterAll}</option>
            <option value="borrowed">{filterBorrowed}</option>
            <option value="available">{filterAvailable}</option>
          </select>
        </div>

        {/* Return by QR Button */}
        <button
          onClick={() => setShowQrModal(true)}
          className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition"
        >
          {returnByQR}
        </button>
      </div>

      {/* If no books after filter, show a message */}
      {displayedBooks.length === 0 ? (
        <div className="bg-white dark:bg-gray-800 shadow-md rounded-xl p-6">
          <p className="text-gray-800 dark:text-gray-200">
            {noBooks}
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 justify-items-center px-4 py-8">
          {displayedBooks.map((book) => (
            <div
              key={book.id}
              className="group relative flex flex-col bg-white dark:bg-gray-800 shadow-md rounded-xl overflow-visible 
                         border-4 border-sky-800 dark:border-white 
                         p-4 w-full max-w-xs transition 
                         hover:border-orange-400 dark:hover:border-orange-500"
            >
              {/* Book Cover & Title Link */}
              <Link to={`${book.id}`} className="block mb-4">
                <div className="w-40 mx-auto">
                  <LazyLoad height={200} offset={100} once placeholder={<Spinner />}>
                    <img
                      src={book.cover_thumbnail || book.cover_image}
                      alt={book.title}
                      loading="lazy"
                      className="w-full h-full object-cover rounded-md transition-transform duration-300 group-hover:scale-110 transform-gpu"
                    />
                  </LazyLoad>
                </div>
              </Link>

              {/* Book Info */}
              <div className="flex-1 flex flex-col justify-between">
                {/* Title */}
                <Link to={`${book.id}`}>
                  <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-100 mb-1 
                                 hover:text-blue-600 transition-colors">
                    {book.title}
                  </h3>
                </Link>

                {/* Author */}
                <p className="text-sm text-gray-600 dark:text-gray-300">
                  {language === 'pl' ? 'Autor' : 'Author'}: {book.author}
                </p>

                {/* Availability */}
                <div
                  className={`mt-2 text-sm font-medium ${
                    book.is_borrowed ? 'text-red-500' : 'text-green-500'
                  }`}
                >
                  {book.is_borrowed ? borrow : 'Available'}
                </div>

                {/* Action Buttons */}
                <div className="mt-4 flex space-x-2 items-center">
                  {/* Borrow Button */}
                  <button
                    onClick={() => handleBorrowClick(book.id)}
                    className={`flex items-center gap-1 px-3 py-1 text-sm rounded transition 
                      ${
                        book.is_borrowed
                          ? 'bg-gray-300 dark:bg-gray-600 cursor-not-allowed'
                          : 'bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600 text-white'
                      }`}
                    disabled={book.is_borrowed}
                    title={language === 'pl' ? "Wypożycz tę książkę" : "Borrow this book"}
                  >
                    <FaHandHoldingMedical className="text-base" />
                    <span>{borrowButton}</span>
                  </button>

                  {/* Return Button */}
                  <button
                    onClick={() => handleReturnClick(book.id)}
                    className={`flex items-center gap-1 px-3 py-1 text-sm rounded transition 
                      ${
                        book.is_borrowed
                          ? 'bg-red-600 hover:bg-red-700 dark:bg-red-500 dark:hover:bg-red-600 text-white'
                          : 'bg-gray-300 dark:bg-gray-600 cursor-not-allowed'
                      }`}
                    disabled={!book.is_borrowed}
                    title={language === 'pl' ? "Zwróć tę książkę" : "Return this book"}
                  >
                    <GiReturnArrow className="text-base" />
                    <span>{returnButton}</span>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* User Selector for Borrowing */}
      <UserSelectorModal
        token={token}
        isOpen={showUserSelector}
        onClose={() => setShowUserSelector(false)}
        onSelect={handleUserSelected}
      />

      {/* Borrow Confirmation */}
      <ConfirmModal
        title={confirmBorrowTitle}
        message={`${confirmBorrowMessage} "${selectedUsername}"?`}
        isOpen={showBorrowConfirm}
        onClose={() => setShowBorrowConfirm(false)}
        onConfirm={confirmBorrow}
        confirmText={confirmBorrowYes}
        cancelText={confirmBorrowCancel}
      />

      {/* Return by QR Modal */}
      <ReturnByQRModal
        isOpen={showQrModal}
        onClose={() => setShowQrModal(false)}
        onConfirmReturn={handleReturnByUuid}
        token={token}
        language={language}
      />
    </div>
  );
};

export default BookList;
