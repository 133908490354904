import React, { useEffect, useState } from 'react';
import axios from 'axios';

const CLOUD_URL = 'https://noproblemschool.pl/api/users/list/'; // Ensure this URL is correct
const LOCAL_URL = 'http://localhost:8000/api/users/list/'; // Use the correct URL
const API_BASE_URL = CLOUD_URL;

const UserSelectorModal = ({
  token,
  isOpen,
  onClose,
  onSelect,
  apiUrl = API_BASE_URL,
}) => {
  const [users, setUsers] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (isOpen) {
      fetchUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // Exclude admin/staff users by filtering
      const nonAdminUsers = response.data.filter((u) => !u.is_staff);
      setUsers(nonAdminUsers);
      setFiltered(nonAdminUsers);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearch(query);

    // Search across username, first_name, and last_name
    const newFiltered = users.filter((user) => {
      //console.log(user)
      const fields = [user.username, user.first_name, user.klasa];
      return fields.some((field) => field?.toLowerCase().includes(query));
    });

    setFiltered(newFiltered);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="fixed inset-0 bg-white dark:bg-gray-800 p-6">
        {/* Close button in top-right corner */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white text-4xl"
        >
          &times;
        </button>

        <h2 className="text-2xl font-semibold mb-6 text-gray-800 dark:text-gray-200 text-center">
          Select a Student
        </h2>
        <input
          type="text"
          value={search}
          onChange={handleSearch}
          placeholder="Search by name, group or username..."
          className="w-full mb-6 px-4 py-2 border border-gray-300 dark:border-gray-700 rounded focus:outline-none dark:bg-gray-700 dark:text-white"
        />

        <div className="max-h-[70vh] overflow-y-auto border border-gray-200 dark:border-gray-700 rounded">
          {filtered.map((u) => (
            <button
              key={u.username}
              onClick={() => {
                onSelect(u.username);
                onClose();
              }}
              className="block w-full text-left px-4 py-3 hover:bg-blue-100 dark:hover:bg-gray-600"
            >
              {/* Display name + username + klasa */}
              <p className="text-sm font-medium text-gray-800 dark:text-gray-100">
                {u.first_name} {u.last_name}
              </p>
              <p className="text-xs text-gray-600 dark:text-gray-300">
                {u.username}
                  {/*  | {u.email}  */}
              </p>
              {/* Display the class if available */}
              {u.klasa ? (
                <p className="text-xs text-gray-600 dark:text-gray-300">
                  Group: {u.klasa}
                </p>
              ) : (
                <p className="text-xs text-gray-500 dark:text-gray-400">No klasa assigned</p>
              )}
            </button>
          ))}
        </div>

        <div className="mt-6 flex justify-center">
          <button
            onClick={onClose}
            className="px-6 py-3 bg-gray-300 dark:bg-gray-700 rounded hover:bg-gray-400 dark:hover:bg-gray-600"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserSelectorModal;





