// src/context/LanguageContext.js

import React, { createContext, useState, useEffect } from 'react';

// Create the context
export const LanguageContext = createContext();

// Create the provider component
export const LanguageProvider = ({ children }) => {
  // Initialize language state, default to 'pl'
  const [language, setLanguage] = useState('en');

  // Optional: Persist language preference in localStorage
  useEffect(() => {
    const storedLanguage = localStorage.getItem('appLanguage');
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }
  }, []);

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => {
      const newLang = prevLanguage === 'pl' ? 'en' : 'pl';
      localStorage.setItem('appLanguage', newLang);
      return newLang;
    });
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
