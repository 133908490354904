// src/components/Navbar.js

import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaHome, FaBars, FaTimes, FaLanguage, FaMedal } from 'react-icons/fa';
import { FiLogIn, FiLogOut } from 'react-icons/fi';
import ThemeToggle from './utils/ThemeToggle'; // Theme toggle component
import { ThemeContext } from '../context/ThemeContext'; // Theme context
import { LanguageContext } from '../context/LanguageContext'; // Import LanguageContext
import Flag from 'react-world-flags';
import { ImBooks } from "react-icons/im";
const Navbar = ({ token, onLogout }) => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { theme } = useContext(ThemeContext); // Access current theme
  const { language, toggleLanguage } = useContext(LanguageContext); // Access language and toggle function

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="sticky top-0 bg-slate-200 dark:bg-gray-900 p-4 text-slate-800 dark:text-gray-200 flex justify-between items-center z-50">
      {/* Logo */}
      <Link to="/library" className="flex items-center space-x-2">
        <img src="/np_small.webp" alt="Logo" className="h-10 w-auto rounded-lg" />
      </Link>

      {/* DESKTOP LINKS (Visible on md and up, hidden on mobile) */}
      <div className="hidden md:flex items-center space-x-4">
        <Link
          to="/library"
          className="text-xl flex items-center space-x-2 hover:text-blue-600 transition"
        >
          <ImBooks className='text-3xl'/>
          <span className="font-light text-lg">Home</span>
        </Link>

        <Link
          to="/leaderboard"
          className="text-xl flex items-center space-x-2 hover:text-blue-600 transition"
        >
          <FaMedal className='text-2xl'/>
          <span>Leaderboard</span>
        </Link>
      </div>

      {/* DESKTOP ACTIONS (Theme, Language, Login/Logout) */}
      <div className="hidden md:flex items-center space-x-4">
        {/* Theme Toggle */}
        <ThemeToggle />

        {/* Language Switcher         <button
          type="button"
          onClick={toggleLanguage}
          className="flex items-center bg-white dark:bg-gray-700 text-blue-900 dark:text-white px-4 py-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 transition duration-300 shadow-lg"
          title={language === 'pl' ? 'Switch to English' : 'Przełącz na Polski'}
        >
          {language === 'pl' ? <Flag country="GB" height={20} /> : <Flag country="PL" height={20} />}
          {language === 'pl' ? 'EN' : 'PL'}
        </button> */}


        {/* Login/Logout Button */}
        {token ? (
          <button
            type="button"
            onClick={handleLogout}
            className="flex items-center space-x-2 bg-red-500 hover:bg-red-600 text-white py-1 px-3 rounded transition"
          >
            <FiLogOut className="text-xl" />
            <span>Logout</span>
          </button>
        ) : (
          <Link
            to="/login"
            className="flex items-center space-x-2 bg-green-500 hover:bg-green-600 py-1 px-3 rounded transition text-white"
          >
            <FiLogIn className="text-xl" />
            <span>Login</span>
          </Link>
        )}
      </div>

      {/* MOBILE ICONS (Visible on mobile, hidden on md and up) */}
      <div className="md:hidden flex items-center space-x-2">
        {/* Language Switcher (Mobile)        <button
          type="button"
          onClick={toggleLanguage}
          className="flex items-center bg-white dark:bg-gray-700 text-blue-900 dark:text-white px-2 py-1 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 transition duration-300 shadow-lg"
          title={language === 'pl' ? 'Switch to English' : 'Przełącz na Polski'}
        >
          <FaLanguage className="mr-1" />
          {language === 'pl' ? 'EN' : 'PL'}
        </button> */}


        {/* Theme Toggle (Mobile) */}
        <ThemeToggle />

        {/* Hamburger Icon */}
        <button
          type="button"
          onClick={toggleMobileMenu}
          className="text-2xl focus:outline-none"
        >
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>

      {/* FULL-SCREEN OVERLAY (Mobile Menu) */}
      {isMobileMenuOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-slate-800 dark:bg-gray-900 z-50 flex flex-col">
          {/* Close Button */}
          <div className="flex justify-end p-4">
            <button
              type="button"
              onClick={toggleMobileMenu}
              className="text-3xl text-white focus:outline-none"
            >
              <FaTimes />
            </button>
          </div>

          {/* Mobile Menu Links */}
          <div className="flex flex-col items-center justify-center flex-grow space-y-8">
            {/* Home */}
            <Link
              to="/library"
              onClick={toggleMobileMenu}
              className="text-3xl text-gray-200 flex items-center space-x-2 hover:text-gray-400 transition"
            >
              <ImBooks className='text-3xl'/>
              <span>Home</span>
            </Link>

            {/* Leaderboard */}
            <Link
              to="/leaderboard"
              onClick={toggleMobileMenu}
              className="text-3xl text-gray-200 flex items-center space-x-2 hover:text-gray-400 transition"
            >
              <FaMedal className='text-2xl'/>
              <span>Leaderboard</span>
            </Link>

            {/* Language Switcher (Already in top-right, but optional here) */}
            {/* 
            <button
              type="button"
              onClick={toggleLanguage}
              className="flex items-center bg-white dark:bg-gray-700 text-blue-900 dark:text-white px-4 py-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 transition duration-300 shadow-lg"
              title={language === 'pl' ? 'Switch to English' : 'Przełącz na Polski'}
            >
              <FaLanguage className="mr-2" />
              {language === 'pl' ? 'EN' : 'PL'}
            </button>
            */}

            {/* Theme Toggle (Mobile Overlay) */}
            <ThemeToggle />

            {/* Login/Logout Button */}
            {token ? (
              <button
                type="button"
                onClick={() => {
                  handleLogout();
                  toggleMobileMenu();
                }}
                className="text-2xl flex items-center space-x-2 bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded transition"
              >
                <FiLogOut className="text-3xl" />
                <span>Logout</span>
              </button>
            ) : (
              <Link
                to="/login"
                onClick={toggleMobileMenu}
                className="text-2xl flex items-center space-x-2 bg-green-500 hover:bg-green-600 py-2 px-4 rounded transition text-white"
              >
                <FiLogIn className="text-3xl" />
                <span>Login</span>
              </Link>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
