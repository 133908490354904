import React from 'react';
import LoginForm from './LoginForm';

const LoginPage = ({ onLoginSuccess }) => {
  return (
    <div className="relative flex min-h-screen flex-col items-center bg-blue-900">
      {/* Hero Section */}
      <div
        className="w-full h-screen bg-cover bg-center flex items-center justify-center"
        style={{ backgroundImage: "url('/books-bg.jpeg')" }}
      >
      <div className="bg-sky-800 p-8 rounded-lg shadow-lg w-full max-w-md">
              <img src="/np_small.webp" alt="Logo" className="h-12 w-auto" />
        <h1 className="text-xl font-bold text-center mb-6 text-blue-100">Login to Your Account</h1>
        <LoginForm onLoginSuccess={onLoginSuccess} />
      </div>
    </div>
    </div>
  );
};

export default LoginPage;
