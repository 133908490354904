// src/api/libraryApi.js

import axios from 'axios';

const CLOUD_URL = 'https://noproblemschool.pl/api/library'; // Ensure this URL is correct
const LOCAL_URL = 'http://localhost:8000/api/library'; // Use the correct URL
const API_BASE_URL = CLOUD_URL;


export const libraryApi = axios.create({
  baseURL: API_BASE_URL,
});

// Example: Get all books
export const getBooks = (token) => {
  return libraryApi.get('/books/', {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Example: Get book details
export const getBookDetails = (bookId, token) => {
  return libraryApi.get(`/books/${bookId}/`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};


// Example: Assign a book
export const assignBook = (bookId, username, token) => {
  return libraryApi.put(
    `/books/${bookId}/assign/`,
    { username },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

// Example: Borrow a book
export const borrowBook = (bookId, token) => {
  return libraryApi.post(
    `/books/borrow/`,
    { book_id: bookId },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

// Example: Return a book
export const returnBook = (bookId, token) => {
  return libraryApi.post(
    `/books/${bookId}/return/`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export const returnBookByUuid = (bookUuid, token) => {
  return libraryApi.post(
    `/books/return_by_uuid/${bookUuid}/`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const getBookByUuid = (uuid, token) => {
  return libraryApi.get(`/books/by_uuid/${uuid}/`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};


export const getTopReaders = (token) => {
  return libraryApi.get('/stats/top_readers/', {
    headers: { Authorization: `Bearer ${token}` },
  });
};


// NEW: Get quotes
export const getQuotes = async () => {
  try {
    const response = await libraryApi.get('/quotes/');
    return response.data.quotes; // Adjust the response structure if necessary
  } catch (error) {
    console.error('Failed to fetch quotes:', error);
    throw error;
  }
};