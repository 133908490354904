// src/components/Leaderboard.js

import React, { useState, useEffect } from 'react';
import { getTopReaders } from '../api/libraryApi';
import { FaCrown, FaMedal } from 'react-icons/fa';

const Leaderboard = ({ token }) => {
  const [readers, setReaders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data on component mount
  useEffect(() => {
    const fetchTopReaders = async () => {
      try {
        setLoading(true);
        const response = await getTopReaders(token);
        setReaders(response.data); // The array of top readers
      } catch (err) {
        setError('Failed to fetch top readers.');
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchTopReaders();
    } else {
      setError('You must be logged in to see the leaderboard.');
      setLoading(false);
    }
  }, [token]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="text-xl font-bold">Loading Leaderboard...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <p className="text-red-500 text-lg">{error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-slate-100 dark:bg-gray-800 p-8 text-slate-800 dark:text-gray-100">
      <div className="max-w-3xl mx-auto">
        {/* Title / Header */}
        <h1 className="text-3xl md:text-4xl font-extrabold text-center mb-8">
          Leaderboard
        </h1>

        {/* Leaderboard Table or Cards */}
        <div className="bg-white dark:bg-gray-700 rounded-lg shadow-lg p-4">
          <table className="w-full table-auto">
            <thead>
              <tr className="border-b border-gray-300 dark:border-gray-600">
                <th className="px-4 py-2 text-left">Rank</th>
                <th className="px-4 py-2 text-left">Reader</th>
                <th className="px-4 py-2 text-left">Books Read</th>
              </tr>
            </thead>
            <tbody>
              {readers.map((reader, index) => {
                // Determine style or icon for top 3
                let highlightClass = '';
                let icon = null;

                if (index === 0) {
                  highlightClass = 'bg-yellow-100 dark:bg-yellow-800 font-bold';
                  icon = <FaCrown className="text-yellow-500 dark:text-yellow-300 inline-block mr-2" />;
                } else if (index === 1) {
                  highlightClass = 'bg-gray-100 dark:bg-gray-800 font-semibold';
                  icon = <FaMedal className="text-gray-500 dark:text-gray-200 inline-block mr-2" />;
                } else if (index === 2) {
                  highlightClass = 'bg-orange-100 dark:bg-orange-800 font-semibold';
                  icon = <FaMedal className="text-orange-500 dark:text-orange-300 inline-block mr-2" />;
                }

                return (
                  <tr
                    key={index}
                    className={`${highlightClass} border-b border-gray-200 dark:border-gray-600`}
                  >
                    {/* Rank */}
                    <td className="px-4 py-2">{icon} {index + 1}</td>

                    {/* Reader (FirstName LastName) */}
                    <td className="px-4 py-2">
                      {reader.first_name} {reader.last_name}
                    </td>

                    {/* Total Books Read */}
                    <td className="px-4 py-2">{reader.total_books_read}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
