// src/context/UserContext.js

import React, { createContext, useState, useEffect } from 'react';
import { api } from '../auth/auth';

export const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setToken(null);
    setUser(null);
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (token) {
        try {
          const response = await api.get('/users/profile', {
            headers: { Authorization: `Bearer ${token}` },
          });
          setUser(response.data);
        } catch (error) {
          console.error('Error fetching user profile:', error);
          handleLogout();
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    };
    fetchUserProfile();
  }, [token]);

  return (
    <UserContext.Provider value={{ user, setUser, token, setToken, handleLogout }}>
      {!loading && children}
    </UserContext.Provider>
  );
};

export default UserProvider;
