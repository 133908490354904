// src/components/Library/AssignBook.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  assignBook,
  getBookDetails,
  returnBook,
} from '../../api/libraryApi';

const AssignBook = ({ token }) => {
  const { bookId } = useParams();
  const navigate = useNavigate();

  // State for assignment
  const [username, setUsername] = useState('');

  // State for fetched book details
  const [book, setBook] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Fetch current book details (including borrow history)
  useEffect(() => {
    const fetchBookData = async () => {
      try {
        const response = await getBookDetails(bookId, token);
        setBook(response.data);
      } catch (err) {
        setError('Failed to fetch book details.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchBookData();
  }, [bookId, token]);

  // Handle assigning the book
  const handleAssign = async (e) => {
    e.preventDefault();
    try {
      await assignBook(bookId, username, token);
      toast.success('Book assigned successfully.');
      // Refresh details or navigate back
      navigate(`/library/${bookId}`);
    } catch (err) {
      toast.error('Failed to assign book.');
      console.error(err);
    }
  };

  // Handle returning the book
  const handleReturn = async () => {
    try {
      await returnBook(bookId, token);
      toast.success('Book returned successfully.');
      // Optionally refetch to update state or navigate back
      navigate(`/library/${bookId}`);
    } catch (err) {
      toast.error('Failed to return book.');
      console.error(err);
    }
  };

  // Handle loading/errors
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg text-gray-700 dark:text-gray-300">
          Loading...
        </div>
      </div>
    );
  }
  if (error) {
    return (
      <div className="text-center mt-10 text-red-600">
        {error}
      </div>
    );
  }
  if (!book) {
    return (
      <div className="text-center mt-10">
        Book not found.
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto mt-10 p-6 bg-white dark:bg-gray-800 shadow-md rounded-lg">
      {/* Book Header */}
      <h2 className="text-2xl font-bold text-gray-800 dark:text-white mb-4">
        {book.title}
      </h2>
      <p className="text-gray-700 dark:text-gray-300">
        <strong>Author:</strong> {book.author}
      </p>
      <p
        className={`mt-2 mb-4 font-semibold ${
          book.is_borrowed ? 'text-red-500' : 'text-green-500'
        }`}
      >
        {book.is_borrowed ? 'Currently Borrowed' : 'Available'}
      </p>

      {/* Borrow History */}
      <div className="mt-4 bg-gray-50 dark:bg-gray-700 p-4 rounded">
        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-2">
          Borrow History
        </h3>
        {book.borrow_history?.length > 0 ? (
          <ul className="space-y-2">
            {book.borrow_history.map((record) => (
              <li
                key={record.id}
                className="p-2 bg-white dark:bg-gray-800 rounded shadow-sm"
              >
                <p className="text-sm text-gray-700 dark:text-gray-300">
                  <strong>{record.borrower}</strong> borrowed on{' '}
                  {new Date(record.borrowed_at).toLocaleDateString()}
                  {record.returned_at
                    ? ` and returned on ${new Date(
                        record.returned_at
                      ).toLocaleDateString()}.`
                    : ' and has not returned yet.'}
                </p>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500">No borrow history.</p>
        )}
      </div>

      {/* Actions */}
      <div className="mt-6">
        {book.is_borrowed ? (
          // If the book is borrowed, show Return button
          <button
            onClick={handleReturn}
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors duration-200"
          >
            Return Book
          </button>
        ) : (
          // If the book is not borrowed, show Assign form
          <form onSubmit={handleAssign}>
            <div className="mb-4">
              <label
                htmlFor="username"
                className="block text-gray-700 dark:text-gray-300 mb-2"
              >
                Assign to Username:
              </label>
              <input
                type="text"
                id="username"
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors duration-200"
            >
              Assign Book
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default AssignBook;
