// src/components/ThemeToggle.jsx

import React, { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { FaSun, FaMoon } from 'react-icons/fa';

const ThemeToggle = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <button
      onClick={toggleTheme}
      className="p-2 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      aria-label="Toggle Dark Mode"
    >
      {theme === 'dark' ? (
        <FaSun className="text-yellow-500" size={24} />
      ) : (
        <FaMoon className="text-gray-800" size={24} />
      )}
    </button>
  );
};

export default ThemeToggle;
